import React, { useContext } from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/MainLayout"
import Seo from "../components/WebSeo"
import * as styles from "./contact.module.scss"
import Card from "../components/common/Card"
import Button from "../components/common/Button"
import Input from "../components/common/Input"
import { PhoneIncoming, AtSign, MapPin } from "react-feather"
import { globalStateContext } from "../context/GlobalContextProvider"
const Contact = ({ data }) => {
  const banner = getImage(data.banner.childImageSharp.gatsbyImageData)
  const textData = data.textData

  const state = useContext(globalStateContext)
  const { language } = state
  const isEnglish = language === "EN"
  return (
    <Layout>
      <Seo title="Contact" />
      <section className={styles.container}>
        <div className={styles.hero}>
          <h3>{isEnglish ? textData.title[0] : textData.title[1]}</h3>
          <h1>{isEnglish ? textData.hero[0] : textData.hero[1]}</h1>
          <div className={styles.contactInfo}>
            <h4>{isEnglish ? textData.subHero[0] : textData.subHero[1]}</h4>
            <div className={styles.icons}>
              <PhoneIncoming /> <span>+33766128060</span>
            </div>
            <div className={styles.icons}>
              <AtSign /> <span>info@anico.pro</span>
            </div>
            <address>
              <div className={styles.address}>
                <MapPin /> <span>co yrytys 34 rue</span>
                <br />
                <p>gioffredo</p>
                <p>06000</p>
                <p>Nice</p>
                <p>France</p>
              </div>
            </address>
          </div>
          <div className={styles.image}>
            <GatsbyImage image={banner} alt="CONTACT US" />
          </div>
        </div>
        <div className={styles.formContainer}>
          <Card>
            <form onSubmit={() => alert("Thank you for getting in touch!")}>
              <Input
                label={isEnglish ? textData.name[0] : textData.name[1]}
                required
              />
              <Input
                label={isEnglish ? textData.email[0] : textData.email[1]}
                type="email"
                required
              />
              <Input
                label={isEnglish ? textData.phone[0] : textData.phone[1]}
                type="phone"
                required
              />
              <Input label="message" isTextarea={true} />
              <div className={styles.button}>
                <Button type="submit">
                  {isEnglish ? "submit" : "nous faire parvenir"}
                </Button>
              </div>
            </form>
          </Card>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query jsonData {
    banner: file(relativePath: { eq: "contact-us-nong.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    textData: contactJson {
      hero
      subHero
      title
      name
      email
      phone
    }
  }
`

export default Contact
